import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import "./users.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

import {
	Box,
	Button,
	Container,
	Input,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
function UsersByEmployee() {
	function formatDate(m) {
		new Date(m);
		const dateString =
			m.getUTCFullYear() +
			"/" +
			("0" + (m.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + m.getDate()).slice(-2) +
			"  " +
			("0" + m.getHours()).slice(-2) +
			":" +
			("0" + m.getMinutes()).slice(-2);
		return dateString;
	}
	const [users, setUser] = useState(null);
	const [searched, setSearched] = useState("");
	const { id } = useParams();
	const [isShow, setShow] = useState(false);
	const [data, setData] = useState(null);
	const [load, setLoad] = useState(false);
	const [profile, setProfile] = useState(null);
	const [bank, setBank] = useState(null);
	const [history, setHistory] = useState(null);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		if (load == false) {
			axios
				.post(`${process.env.REACT_APP_API_URL}/auth/getCustomerEmployee`, {
					code: id,
				})
				.then((res) => {
					localStorage.setItem("data", JSON.stringify(res.data.data));
					setUser(res.data.data);
					setLoad(true);
				})
				.then((res) => setLoad(true));
		}
		if (id && load == false) {
			axios
				.post(`${process.env.REACT_APP_API_URL}/bank/getBankUser`, { id: id })
				.then((res) => {
					setData(res.data.data);
					setLoad(true);
				})
				.catch((res) => setData(null));
		}
		axios
			.get(`${process.env.REACT_APP_API_URL}/auth/user/${id}`, {})
			.then((res) => {
				setProfile(res.data.data);
			})
			.catch((res) => setProfile(null));
		axios
			.get(`${process.env.REACT_APP_API_URL}/history/historyuser/${id}`, {})
			.then((res) => {
				setHistory(res.data.data);
			})
			.catch((res) => setHistory(null));
	}, [load]);
	const requestSearch = (searchedVal) => {
		setSearched(searchedVal);

		if (searchedVal !== "") {
			const filteredRows = JSON.parse(localStorage.getItem("data")).filter(
				(row) => {
					return row.username.toLowerCase().includes(searchedVal.toLowerCase());
				}
			);
			setUser(filteredRows);
		} else {
			setUser(JSON.parse(localStorage.getItem("data")));
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		axios
			.post(`${process.env.REACT_APP_API_URL}/auth/changepassword`, {
				id: id,
				password: e.target.password.value,
			})
			.then((res) => {
				setLoad(false);
				swal("Cập nhật thành công");
			})
			.catch((err) => {
				swal("Có lỗi vui lòng thử lại!");
				setData(null);
			});
	};
	const handleSubmitBank = (e) => {
		e.preventDefault();
		const formData = {
			id: bank._id,
			name_bank: e.target.name_bank.value,
			stk: e.target.stk.value,
			fullname: e.target.fullname.value,
		};
		axios
			.post(`${process.env.REACT_APP_API_URL}/bank/updateBank`, formData)
			.then((res) => {
				setShow(false);
				swal("Cập nhật thành công");
				setLoad(false);
			})
			.catch((err) => {
				setShow(false);
				swal("Có lỗi vui lòng thử lại!");
				setLoad(false);
			});
	};
	return (
		<>
			<ThemeProvider theme={theme}>
				<DashboardLayout>
					{
						<Box
							component="main"
							sx={{
								flexGrow: 1,
								py: 1,
							}}
						>
							{id.length > 9 ? (
								<>
									<Container maxWidth={false}>
										<div style={{ fontSize: "25px", fontWeight: 700 }}>
											Chỉnh sửa thông tin
										</div>
										<div className="item_accountprofile">
											<div className="account_profile">
												<div className="titleitem">Thông tin</div>
												{profile != null ? (
													<>
														<div className="avb_">
															<div className="detail_user">
																<div className="username_">Tên đăng nhập</div>
																<div className="username_">
																	{profile.username}
																</div>
															</div>
															<div className="detail_user">
																<div className="username_">Tiền user</div>
																<div className="username_">
																	{Math.floor(profile.money).toLocaleString()}
																</div>
															</div>
															<form onSubmit={handleSubmit}>
																<div className="detail_user">
																	<div className="username_">Mật khẩu</div>
																	<TextField name="password" />
																	<Button type="submit">Xác nhận</Button>
																</div>
															</form>
															<div className="detail_user">
																<div className="username_">
																	Tiền đã đặt cược
																</div>
																<div className="username_">
																	{Number(profile.totalbet).toLocaleString()}
																</div>
															</div>
															<div className="detail_user">
																<div className="username_">Tiền đã win</div>
																<div className="username_">
																	{Number(profile.totalwin).toLocaleString()}
																</div>
															</div>
															<div className="detail_user">
																<div className="username_">Ngày lập nick</div>
																<div className="username_">
																	{formatDate(new Date(profile.createdAt))}
																</div>
															</div>
															<div className="detail_user">
																<div className="username_">SĐT</div>
																<div className="username_">
																	{profile.sdt
																		? "0" + profile.sdt
																		: "Chưa có sđt"}
																</div>
															</div>
														</div>
													</>
												) : null}
											</div>
											<div className="edit_account_bankl">
												<div className="titleitem">
													Danh sách ngân hàng USER
												</div>
												<Table sx={{ width: 700 }}>
													<TableHead>
														<TableRow>
															<TableCell>Tên Ngân Hàng</TableCell>
															<TableCell>STK</TableCell>
															<TableCell>Người Nhận</TableCell>
															<TableCell>Hành động</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{data != null ? (
															<>
																{data.map((item) => (
																	<TableRow>
																		<TableCell sx={{ fontWeight: "600" }}>
																			{item.name_bank}
																		</TableCell>
																		<TableCell sx={{ fontWeight: "600" }}>
																			{" "}
																			{item?.stk}
																		</TableCell>
																		<TableCell sx={{ fontWeight: "600" }}>
																			{item.fullname}
																		</TableCell>
																		<TableCell sx={{ fontWeight: "600" }}>
																			<Button
																				onClick={() => {
																					axios
																						.delete(
																							`${process.env.REACT_APP_API_URL}/bank/delete/${item._id}`
																						)
																						.then((res) => {
																							setLoad(false);
																						});
																				}}
																			>
																				Xóa
																			</Button>
																			<Button
																				onClick={() => {
																					axios
																						.get(
																							`${process.env.REACT_APP_API_URL}/bank/user/${item._id}`,
																							{}
																						)
																						.then((res) => {
																							setBank(res.data.data);
																							setShow(true);
																						})
																						.catch((res) => {
																							swal(
																								"Lấy thông tin không thành công"
																							);
																							setLoad(false);
																						});
																				}}
																			>
																				Sửa
																			</Button>
																		</TableCell>
																	</TableRow>
																))}
															</>
														) : (
															<div
																style={{
																	fontSize: "16px",
																	textAlign: "center",
																	padding: "10px",
																}}
															>
																Đang cập nhật dữ liệu
															</div>
														)}
													</TableBody>
												</Table>
											</div>
										</div>
										<Table sx={{ width: "100%" }}>
											<TableHead>
												<TableRow>
													<TableCell>Tên người chơi</TableCell>
													<TableCell>Game</TableCell>
													<TableCell>Loại</TableCell>
													<TableCell>Coin</TableCell>
													<TableCell>Coin nhận</TableCell>
													<TableCell>Thời gian</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{history != null ? (
													<>
														{history.map((item) => (
															<TableRow>
																<TableCell sx={{ fontWeight: "600" }}>
																	{item.name}
																</TableCell>
																<TableCell sx={{ fontWeight: "600" }}>
																	{" "}
																	{item.game}
																</TableCell>
																<TableCell sx={{ fontWeight: "600" }}>
																	{item.detail}
																</TableCell>
																<TableCell sx={{ fontWeight: "600" }}>
																	{item.money}
																</TableCell>
																<TableCell sx={{ fontWeight: "600" }}>
																	{item.money_recv}
																</TableCell>
																<TableCell sx={{ fontWeight: "600" }}>
																	{formatDate(new Date(item.time))}
																</TableCell>
															</TableRow>
														))}
													</>
												) : (
													<div
														style={{
															fontSize: "16px",
															textAlign: "center",
															padding: "10px",
														}}
													>
														Đang cập nhật dữ liệu
													</div>
												)}
											</TableBody>
										</Table>
									</Container>
									{isShow === true ? (
										<>
											<div className="modal">
												<div className="modaloverlay">
													<i className="ti-close closelogin"></i>
												</div>
												<div className="modalbody">
													{bank != null ? (
														<>
															<form onSubmit={handleSubmitBank}>
																<div className="modalinner">
																	<div className="modalheader">
																		{" "}
																		Sửa thông tin{" "}
																	</div>

																	<div className="modalform">
																		<div
																			className="modalformgroup"
																			style={{ padding: "9px" }}
																		>
																			<TextField
																				name="name_bank"
																				defaultValue={bank.name_bank}
																				sx={{ width: "100%" }}
																				type="text"
																				placeholder="Tên ngân hàng"
																			/>
																		</div>
																		<div
																			style={{ padding: "9px" }}
																			className="modalformgroup"
																		>
																			<TextField
																				name="stk"
																				defaultValue={bank.stk}
																				sx={{ width: "100%" }}
																				type="number"
																				placeholder="STK"
																			/>
																		</div>
																		<div
																			style={{ padding: "9px" }}
																			className="modalformgroup"
																		>
																			<TextField
																				name="fullname"
																				defaultValue={bank.fullname}
																				sx={{ width: "100%" }}
																				type="text"
																				placeholder="Tên tài khoản"
																			/>
																		</div>
																	</div>

																	<div className="item_btn_form">
																		<div className="modalformcontrols">
																			<Button type="submit">XÁC NHẬN</Button>
																		</div>
																		<div className="modalformcontrols">
																			<Button onClick={() => setShow(false)}>
																				ĐÓNG
																			</Button>
																		</div>
																	</div>
																</div>
															</form>
														</>
													) : (
														<div>Đang chờ dữ liệu</div>
													)}
												</div>
											</div>
										</>
									) : null}
								</>
							) : (
								<Container maxWidth={false}>
									<div className="container_set">Danh sách user</div>
									{users ? (
										<>
											<div className="form_set">
												<Box sx={{ minWidth: 600 }}>
													<TextField
														value={searched}
														onChange={(searchVal) =>
															requestSearch(searchVal.target.value)
														}
														placeholder="Tìm kiếm"
														sx={{ marginBottom: "5px", paddingRight: "700px" }}
													/>
													<Table sx={{ width: 1600 }}>
														<TableHead>
															<TableRow>
																<TableCell>ID User</TableCell>
																<TableCell>Username</TableCell>
																<TableCell>Số tiền</TableCell>
																<TableCell>Ngày tạo</TableCell>
																<TableCell>Admin Cộng</TableCell>

																<TableCell>Admin Trừ</TableCell>
																<TableCell>Admin Thưởng</TableCell>
																<TableCell>Tổng đặt</TableCell>
																{/* <TableCell>Rút/ Nạp Tiền</TableCell>
														<TableCell>Thưởng</TableCell>
														<TableCell>Xem thông tin</TableCell> */}
															</TableRow>
														</TableHead>
														<TableBody>
															{users.map((item) => (
																<>
																	<TableRow>
																		<TableCell sx={{ fontWeight: "600" }}>
																			{item?.iduser}
																		</TableCell>
																		<TableCell sx={{ fontWeight: "600" }}>
																			{item.username}
																		</TableCell>
																		<TableCell sx={{ fontWeight: "600" }}>
																			{item.money.toLocaleString()}
																		</TableCell>
																		<TableCell sx={{ fontWeight: "600" }}>
																			{formatDate(new Date(item?.createdAt))}
																		</TableCell>
																		<TableCell sx={{ fontWeight: "600" }}>
																			{item.adminadd.toLocaleString()}
																		</TableCell>
																		<TableCell sx={{ fontWeight: "600" }}>
																			{item.admintru.toLocaleString()}
																		</TableCell>
																		<TableCell sx={{ fontWeight: "600" }}>
																			{item.adminthuong.toLocaleString()}
																		</TableCell>
																		<TableCell sx={{ fontWeight: "600" }}>
																			{item.totalbet.toLocaleString()}
																		</TableCell>
																		{/* <TableCell sx={{ fontWeight: "600" }}>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			const dataForm = {
																				id: item._id,
																				money: e.target.money.value,
																			};
																			axios
																				.post(
																					`${process.env.REACT_APP_API_URL}/auth/update`,
																					dataForm
																				)
																				.then((res) => {
																					setUser(res.data.data);
																					setLoad(false);
																					swal("Cộng/Trừ tiền thành công!")
																				});
																		}}
																	>
																		<input
																			style={{ width: "100px" }}
																			id={item._id}
																			name="money"
																			type="number"
																		/>
																		<Button type="submit">Xác nhận</Button>
																	</form>
																</TableCell> */}
																		{/* <TableCell sx={{ fontWeight: "600" }}>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			const dataForm = {
																				id: item._id,
																				money: e.target.money.value,
																			};
																			axios
																				.post(
																					`${process.env.REACT_APP_API_URL}/auth/adminthuong`,
																					dataForm
																				)
																				.then((res) => {
																					
																					setLoad(false);
																					swal("Thưởng thành công!")
																				});
																		}}
																	>
																		<input
																			style={{ width: "100px" }}
																			id={item._id}
																			name="money"
																			type="number"
																		/>
																		<Button type="submit">Xác nhận</Button>
																	</form>
																</TableCell> */}
																		{/* <TableCell
																	sx={{ fontWeight: "600", display: "flex" }}
																>
																	{item.isLock == false ? (
																		<Button
																			onClick={() => {
																				axios
																					.post(
																						`${process.env.REACT_APP_API_URL}/auth/lockkey`,
																						{
																							id: item._id,

																							isLock: true,
																						}
																					)
																					.then((res) => {
																						setLoad(false);
																						
																					});
																			}}
																		>
																			Khóa
																		</Button>
																	) : (
																		<Button
																			onClick={() => {
																				axios
																					.post(
																						`${process.env.REACT_APP_API_URL}/auth/lockkey`,
																						{
																							id: item._id,

																							isLock: false,
																						}
																					)
																					.then((res) => {
																						setLoad(false);
																						
																					});
																			}}
																		>
																			Mở
																		</Button>
																	)}
																	<Button
																		onClick={() =>
																			navigate(`/admin/user/${item._id}`)
																		}
																	>
																		Xem
																	</Button>
																</TableCell> */}
																	</TableRow>
																</>
															))}
														</TableBody>
													</Table>
												</Box>
											</div>
										</>
									) : (
										<div>Hiện chưa có user</div>
									)}
								</Container>
							)}
						</Box>
					}
				</DashboardLayout>
			</ThemeProvider>
		</>
	);
}
export default UsersByEmployee;
