import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Button, ListItem } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
// import NotificationsList from "./notification/notification";
export const Notifications = (props) => {
  const { notification, openNotifi, ...other } = props;
 
  
  return (
    <Menu
      anchorEl={notification}
      id="notification-list"
      open={openNotifi}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      {...other}
    >   
        {/* <NotificationsList/> */}
    </Menu>
  );
};
