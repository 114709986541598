import { useEffect, useState } from "react";
import LiveChat from "react-livechat";
import Footer from "../../components/Footer/Footer";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";

function CSKH() {
	const [profile, setProfile] = useState(null);
	const [isShow, setShow] = useState(false);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);

	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		setIsLoading(true);
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, []);
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
			.then((res) => {
				setProfile(res.data.data);
			})
			.catch((err) => localStorage.removeItem("user"));
	}, []);
	return (
		<>
			{isLoading ? (
				<div className="loading">
					<div className="loader"></div>
				</div>
			) : null}
			<div className="main" style={{ paddingBottom: "0" }}>
				<div
					style={{
						position: "relative",
						height: "100vh",
						margin: "0 -0.32rem",
					}}
				>
					{/*<div
						style={{
							position: "fixed",
							top: "5px",
							left: "15px",
							height: "40px",
							width: "40px",
							lineHeight: "30px",
							background: "red",
							borderRadius: "100%",
						}}
						onClick={() => navigate("/")}
					>
						<KeyboardArrowLeftOutlinedIcon
							sx={{ fontSize: "30px", color: "#fff" }}
						/>
					</div>*/}
					<iframe
						src="https://secure.livechatinc.com/licence/18181437/v2/open_chat.cgi"
						frameborder="0"
						width="100%"
						height="90%"
					></iframe>
					<Footer />
				</div>
			</div>
		</>
	);
}

export default CSKH;
