export const hs = function() {
    return 'host';
}

export const ns = function() {
    return 'ke'
}

export const bs = function() {
    return 'no'
}